// Layout
//
// Styles for managing the structural hierarchy of the site.

.container {
  max-width: 60rem;
  padding-left:  1.5rem;
  padding-right: 1.5rem;
  margin-left:  auto;
  margin-right: auto;
}

.post-list {
  dl {
    display: grid;
    grid-template-columns: max-content auto;
  }

  dt {
    grid-column-start: 1;
    margin-right: 2vw;
    color: $gray-2;
  }

  dd {
    grid-column-start: 2;
    margin: 0;
  }
}

footer {
  margin-bottom: 2rem;
}

footer .license {
  display: inline-block;
}
