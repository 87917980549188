// Masthead
//
// Super small header above the content for site name and short description.

.masthead {
  padding-top:    1rem;

  hr {
    margin: 1rem 0;
  }
}

.masthead-title {
  margin-top: 0;
  margin-bottom: 0;
  color: $gray-5;

  a {
    color: inherit;
  }
}

h1#posts {
  margin-top: 1.5rem;
  font-size: 1.25rem;
}