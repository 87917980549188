// Posts and pages
//
// Each post is wrapped in `.post` and is used on default and post layouts. Each
// page is wrapped in `.page` and is only used on the page layout.

.page,
.post {
  display: inline-block;
  margin-bottom: 2em;

  li + li {
    margin-top: .25rem;
  }
}

// Blog post or page title
.page-title,
.post-title,
.post-title a {
  color: $gray-5;
}
.page-title,
.post-title {
  margin-top: 0;
}

// Meta data line below post title
.post-date {
  display: block;
  margin-top: -.5rem;
  margin-bottom: 1rem;
  color: $gray-2;
}

article {
  width: 100%;
}

.youtube {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.youtube::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.youtube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}